export const boxSetString = (box) => {
    switch (box) {
        case 1:
            return '#1 Thug Busters';
        case 2:
            return '#2 Cosmic Crunchers';
        case 3:
            return '#3 Mighty Maulers';
        case 4:
            return '#4 Cosmic Showdown';
        default:
            return undefined;
    }
}

export const getColorClass = (color) => `color-badge-${color.replace(/\s/g, '')}`;

export const getColorAbbr = (color) => {
    const words = color.split(' '),
          abbr = words.map(word => word.charAt(0));
    return abbr.join('');
};

// to be nice about the image paths.
export const getDudeImg = (dude) => {
    if (dude) {
        let picUrl = 'figures/';
        if (dude < 10) {
            picUrl += `00${dude}.png`;
        } else if (dude > 9 && dude < 100) {
            picUrl += `0${dude}.png`;
        } else if (dude >= 100) {
            picUrl += `${dude}.png`;
        }

        return imgUrl + picUrl;
    }
    else {
        console.error('bad dude id passed for an image...');
        return
    }
}

// what colors does a fig have?
export const getFigColors = (dude) => {
    let hasColors = [];
    dude.colors.forEach((color) => {
        if (color.exists) {
            hasColors.push(color);
        }
    });
    return hasColors;
};

// they click, we do stuff.
export const handleMyDudes = (dude, set) => {
    set(prevDudes => ({
        ...prevDudes,
        [dude]: !prevDudes[dude] 
    }));
};

// images folder path
export const imgUrl = 'images/';

// localstorage key
export const localStoreKey = 'muscle_dudes',
             fleshOnlyKey = 'isFleshOnly';

// trim the fat before it's saved.
export const cleanMyDudes = (dudes) => Object.entries(dudes)
                .filter(([, value]) => value !== false && value !== null) 
                .reduce((obj, [key, value]) => {
                    obj[key] = value;
                    return obj;
                },
            {});