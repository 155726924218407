import React from 'react';
import { chojin } from '../../assets/chojin';

function StatsViewComponent({myDudes, isFleshOnly}) {

    const getPercent = (val1, val2) => `${(val1 / val2 * 100).toFixed(1)}%`;

    const totalChojin = () => {
        let count = 0;
        chojin.forEach((fig) => {
          fig.colors.forEach((color) => {
            if (color.exists === true) {
              count++
              // console.warn(`${fig.name} - ${color.color} - ${color.exists}`)
            }
          });
        })
        return count;
      }
    
      const howManyChojin = totalChojin(),
            currentTeam = Object.keys(myDudes).length;

      const countTheColor = (isStored) => {
        // got this all separated in case I want to get silly with it.
        let flesh = [],
            dBlue = [],
            lBlue = [],
            green = [],
            red = [],
            salmon = [],
            orange = [],
            purple = [],
            grape = [],
            magenta = [];

        const colorMatch = (color) => {
            switch(true) {
                case color.endsWith('Flesh'):
                    flesh++;
                    break;
                case color.endsWith('DarkBlue'):
                    dBlue++;
                    break;
                case color.endsWith('LightBlue'):
                    lBlue++;
                    break;
                case color.endsWith('Green'):
                    green++;
                    break;
                case color.endsWith('Red'):
                    red++;
                    break;
                case color.endsWith('Salmon'):
                    salmon++;
                    break;
                case color.endsWith('Orange'):
                    orange++;
                    break;
                case color.endsWith('Purple'):
                    purple++;
                    break;
                case color.endsWith('Grape'):
                    grape++;
                    break;
                case color.endsWith('Magenta'):
                    magenta++;
                    break;
                default:
            }
        }

        if (isStored) {
            Object.keys(myDudes).forEach((color) => {
                colorMatch(color.replace(' ', ''));
            });
        } else {
            chojin.forEach((fig) => {
                fig.colors.forEach((color) => {
                    if (color.exists === true) {
                        colorMatch(color.color.replace(' ', ''));
                    }
                });
            })
        }

        return {
            'flesh': flesh > 0 ? flesh : 0,
            'dBlue': dBlue > 0 ? dBlue : 0,
            'lBlue': lBlue > 0 ? lBlue : 0,
            'green': green > 0 ? green : 0,
            'red': red > 0 ? red : 0,
            'salmon': salmon > 0 ? salmon : 0,
            'orange': orange > 0 ? orange : 0,
            'purple': purple > 0 ? purple : 0,
            'grape': grape > 0 ? grape : 0,
            'magenta': magenta > 0 ? magenta : 0,
        };
      }

      const countTheBonanno = (isStored) => {
        let classA = [],
            classB = [],
            classC = [];

            const bonannoMatch = (blah) => {
                switch (blah) {
                    case 'A':
                        classA++;
                        break;
                    case 'B':
                        classB++;
                        break;
                    case 'C':
                        classC++;
                        break;
                    default:
                        if (blah) {
                            console.warn('may be a corrupt bonanno class saved - ', blah);
                        }
                }
            }

            if (isStored) {
                Object.keys(myDudes).forEach((color) => {
                    const [, bonannoClass] = color.split("-");
                    bonannoMatch(bonannoClass);
                });
            } else {
                chojin.forEach((fig) => {
                    fig.colors.forEach((color) => {
                        if (color.exists === true && color.class) {
                            bonannoMatch(color.class);
                        }
                    });
                });
            }

            return {
                'A': classA > 0 ? classA : 0,
                'B': classB > 0 ? classB : 0,
                'C': classC > 0 ? classC : 0
            }
        
      }
    
      const allColorCounts = countTheColor(false),
            myColorCounts = countTheColor(true),
            allBonanno = countTheBonanno(false),
            myBonanno = countTheBonanno(true);
  
  return (
    <>
        <section id="stats-view">
            {isFleshOnly === 'false' && <>
                <article id="collection-breakdown" className="dFlex flexWrap spaceEvenly">
                    <span>
                        {currentTeam} of {howManyChojin} Found
                    </span>
                    <span>
                        {howManyChojin - currentTeam} Missing
                    </span>
                    <span>
                        {getPercent(currentTeam, howManyChojin)} Complete
                    </span>
                </article>
            </>}

            <div className="dFlex flexWrap spaceEvenly">
                <article id="color-breakdown">
                    <h2>Color Summary</h2>
                    <div className={`color-badge-Flesh ${myColorCounts.flesh >= allColorCounts.flesh && 'completed'}`}>
                        <strong>Flesh</strong>
                        <span>{getPercent(myColorCounts.flesh, allColorCounts.flesh)}</span>
                        <span>{myColorCounts.flesh} of {allColorCounts.flesh}</span>
                    </div>
                    {isFleshOnly === 'false' && <>
                        <div className={`color-badge-DarkBlue ${myColorCounts.dBlue >= allColorCounts.dBlue && 'completed'}`}>
                            <strong>Dark Blue</strong>
                            <span>{getPercent(myColorCounts.dBlue, allColorCounts.dBlue)}</span>
                            <span>{myColorCounts.dBlue} of {allColorCounts.dBlue}</span>
                        </div>
                        <div className={`color-badge-LightBlue ${myColorCounts.lBlue >= allColorCounts.lBlue && 'completed'}`}>
                            <strong>Light Blue</strong>
                            <span>{getPercent(myColorCounts.lBlue, allColorCounts.lBlue)}</span>
                            <span>{myColorCounts.lBlue} of {allColorCounts.lBlue}</span>
                        </div>
                        <div className={`color-badge-Green ${myColorCounts.green >= allColorCounts.green && 'completed'}`}>
                            <strong>Green</strong>
                            <span>{getPercent(myColorCounts.green, allColorCounts.green)}</span>
                            <span>{myColorCounts.green} of {allColorCounts.green}</span>
                        </div>
                        <div className={`color-badge-Red ${myColorCounts.red >= allColorCounts.red && 'completed'}`}>
                            <strong>Red</strong>
                            <span>{getPercent(myColorCounts.red, allColorCounts.red)}</span>
                            <span>{myColorCounts.red} of {allColorCounts.red}</span>
                        </div>
                        <div className={`color-badge-Salmon ${myColorCounts.salmon >= allColorCounts.salmon && 'completed'}`}>
                            <strong>Salmon</strong>
                            <span>{getPercent(myColorCounts.salmon, allColorCounts.salmon)}</span>
                            <span>{myColorCounts.salmon} of {allColorCounts.salmon}</span>
                        </div>
                        <div className={`color-badge-Orange ${myColorCounts.orange >= allColorCounts.orange && 'completed'}`}>
                            <strong>Orange</strong>
                            <span>{getPercent(myColorCounts.orange, allColorCounts.orange)}</span>
                            <span>{myColorCounts.orange} of {allColorCounts.orange}</span>
                        </div>
                        <div className={`color-badge-Purple ${myColorCounts.purple >= allColorCounts.purple && 'completed'}`}>
                            <strong>Purple</strong>
                            <span>{getPercent(myColorCounts.purple, allColorCounts.purple)}</span>
                            <span>{myColorCounts.purple} of {allColorCounts.purple}</span>
                        </div>
                        <div className={`color-badge-Grape ${myColorCounts.grape >= allColorCounts.grape && 'completed'}`}>
                            <strong>Grape</strong>
                            <span>{getPercent(myColorCounts.grape, allColorCounts.grape)}</span>
                            <span>{myColorCounts.grape} of {allColorCounts.grape}</span>
                        </div>
                        <div className={`color-badge-Magenta ${myColorCounts.magenta >= allColorCounts.magenta && 'completed'}`}>
                            <strong>Magenta</strong>
                            <span>{getPercent(myColorCounts.magenta, allColorCounts.magenta)}</span>
                            <span>{myColorCounts.magenta} of {allColorCounts.magenta}</span>
                        </div>
                    </>}
                </article>

                {isFleshOnly === 'false' && <>
                    <article>
                        <h2>Bonanno Checklist Summary</h2>
                        <div className="dFlex">
                            <div className={`bonanno-stat-class ${myBonanno.A >= allBonanno.A && 'completed'}`}>
                                <div>Class A</div>
                                <h3>{getPercent(myBonanno.A, allBonanno.A)}</h3>
                                <span>{myBonanno.A} of {allBonanno.A}</span>
                            </div>
                            <div className={`bonanno-stat-class ${myBonanno.B >= allBonanno.B && 'completed'}`}>
                                <div>Class B</div>
                                <h3>{getPercent(myBonanno.B, allBonanno.B)}</h3>
                                <span>{myBonanno.B} of {allBonanno.B}</span>
                            </div>
                            <div className={`bonanno-stat-class ${myBonanno.C >= allBonanno.C && 'completed'}`}>
                                <div>Common</div>
                                <h3>{getPercent(myBonanno.C, allBonanno.C)}</h3>
                                <span>{myBonanno.C} of {allBonanno.C}</span>
                            </div>
                        </div>
                    </article>
                </>}

            </div>
        </section>
    </>
  );
}

export default StatsViewComponent;