import React from 'react';
import { chojin } from '../../assets/chojin';
import { getDudeImg, handleMyDudes } from '../../assets/utils';

function MouldsViewComponent({myDudes, setMyDudes, editing}) {

    return (
        <>
        <section id="moulds-view">
            <h1>Full Color Mould Sets</h1>
            <p>Note: Colors shown are artificial and <em>*not* actual images</em> of each individual figure....unless you can help me obtain them all! ¯\_(ツ)_/¯ </p>
            {chojin.map((fig, i) => 
                <article className="moulds-box" key={`${fig.id}-${i}`}>
                    <div className="mould-title">
                        <h2>#{fig.id} {fig.name}</h2>
                    </div>
                    
                    <div className="dFlex">
                    {fig.colors.map((color, i) =>
                        <div className="fig-mould" key={`${fig.id}-${i}`}>
                            {color.exists && 
                                <>
                                    <figure>
                                        <img src={getDudeImg(fig.id)} alt={`${fig.id}${color.color}`} className={`fig-color-${color.color.replace(' ', '')}`}/>
                                        <figcaption>
                                            <label>
                                                <input type="checkbox"
                                                    id={`${fig.id}-${color.class}-${color.color.replace(' ', '')}`}
                                                    name={`${fig.id}-${color.class}-${color.color.replace(' ', '')}`}
                                                    checked={myDudes[`${fig.id}-${color.class}-${color.color.replace(' ', '')}`] || false}
                                                    onChange={() => handleMyDudes(`${fig.id}-${color.class}-${color.color.replace(' ', '')}`, setMyDudes)}
                                                    disabled={editing}/>
                                                {color.color}
                                            </label>
                                        </figcaption>
                                    </figure>
                                    {(color.class === 'A' || color.class === 'B') &&
                                        <span className={color.class === 'A' ? 'class-badge gold-star' : 'class-badge'}
                                            title={`Brian Bonanno Guide: Class ${color.class} figure`} >
                                            {color.class}
                                        </span>
                                    }

                                </>
                            }
                        </div>
                    )}
                    </div>
                </article>
            )}
        </section>
        </>
    );
}

export default MouldsViewComponent;