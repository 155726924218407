import React, {useEffect, useState} from 'react';
import ShowcaseComponent from './features/showcase/ShowcaseComponent.jsx';
import './App.scss';
import ListViewComponent from './features/listView/listViewComponent.jsx';
import PackagesViewComponent from './features/packagesView/packagesViewComponent.jsx';
// import TreeViewComponent from './features/treeView/treeViewComponent.jsx';
import CreditsComponent from './features/credits/creditsComponent.jsx';
import MouldsViewComponent from './features/moulds/mouldsViewComponent.jsx';
import MissingFiguresComponent from './features/trading/missingFiguresComponent.js';
import StatsViewComponent from './features/stats/statsViewComponent.jsx';
import OptionsComponent from './features/options/OptionsComponent.jsx';
import { cleanMyDudes, localStoreKey, fleshOnlyKey } from './assets/utils.js';


function App() {
  const [ editing, setEditing ] = useState(true),
        [ viewtype, setViewType ] = useState(() => localStorage.getItem('current-view')),
        [ rightNavView, setRightNavView ] = useState(false),
        [ fleshOnly, setFleshOnly ] = useState(() => {
          const localFleshOpt = localStorage.getItem(fleshOnlyKey);
          return localFleshOpt ? localFleshOpt : 'false';
        }),
        [ myDudes, setMyDudes] = useState(() => {
          const localDudes = localStorage.getItem(localStoreKey);
          return localDudes ? JSON.parse(localDudes) : {};
        });

  useEffect(() => {
    let freshDudes = cleanMyDudes(myDudes);
    if (fleshOnly === null) {
      setFleshOnly(false);
      localStorage.setItem(fleshOnlyKey, false);
    } else {
      localStorage.setItem(localStoreKey, JSON.stringify(freshDudes))
      localStorage.setItem(fleshOnlyKey, fleshOnly);
    }
    if (viewtype === null) {
        setViewType('showcase');
    } else {
      localStorage.setItem('current-view', viewtype);
    }
  }, [myDudes, fleshOnly, viewtype]);

  const handleView = (e) => {
    setViewType(e.target.value);
  }

  const handleRightNavView = () => {
    setRightNavView(!rightNavView);
  }

  const handleEdit = (e) => {
    setEditing(!e.target.checked)
  }

  const allowEdit = (e) => {
    switch (viewtype) {
      case 'packages':
      case 'treepart':
      case 'trading':
      case 'stats':
      case 'options':
      case 'credits':
        return false;
      default:
        return true;
    }
  }

  return (
    <>
    <h1 id="temp-words" style={{textAlign: 'center', color: 'red'}}>Under Chojin-struction<br/>-CW</h1>
    <main className="chojin-muscle">
      
      {viewtype === 'showcase' && <ShowcaseComponent myDudes={myDudes} setMyDudes={setMyDudes} editing={editing} isFleshOnly={fleshOnly}/>}
      {viewtype === 'listview' && <ListViewComponent myDudes={myDudes} setMyDudes={setMyDudes} editing={editing} isFleshOnly={fleshOnly}/>}
      {viewtype === 'packages' && <PackagesViewComponent />}
      {/* {viewtype === 'treepart' && <TreeViewComponent />} */}
      {(fleshOnly === 'false' && viewtype === 'moulds') && 
        <MouldsViewComponent myDudes={myDudes} setMyDudes={setMyDudes} editing={editing}/>
      }
      
      {viewtype === 'missing' && <MissingFiguresComponent myDudes={myDudes} setMyDudes={setMyDudes} editing={editing} isFleshOnly={fleshOnly}/>}
      {viewtype === 'stats' && <StatsViewComponent myDudes={myDudes} isFleshOnly={fleshOnly}/>}
      {viewtype === 'options' && <OptionsComponent myDudes={myDudes} setMyDudes={setMyDudes} isFleshOnly={fleshOnly} setFleshOnly={setFleshOnly}/>}
      {viewtype === 'credits' && <CreditsComponent />}


      <nav id="right-nav">
        <div id="right-nav-toggler">
          <input type="checkbox" id="right-nav-toggle" checked={rightNavView} onChange={handleRightNavView}/>
          <label htmlFor="right-nav-toggle" title="Collapse / Expand Nav"></label>
        </div>

        <div id="view-board" className={`${rightNavView && 'right-nav-display'}`}>
          <input type="radio" name="viewtype" value="showcase"
                 id="toggle-showcase"
                 onChange={handleView} checked={viewtype === 'showcase'}/>
          <label htmlFor="toggle-showcase">Showcase</label>

          <input type="radio" name="viewtype" value="listview"
                 id="toggle-listview"
                 onChange={handleView} checked={viewtype === 'listview'}/>
          <label htmlFor="toggle-listview">List</label>

          <input type="radio" name="viewtype" value="packages"
                 id="toggle-packages"
                 onChange={handleView} checked={viewtype === 'packages'}/>
          <label htmlFor="toggle-packages">Packages</label>

          {/* <input type="radio" name="viewtype" value="treepart"
                 id="toggle-treepart"
                 onChange={handleView} checked={viewtype === 'treepart'}/>
          <label htmlFor="toggle-treepart">Tree</label> */}

          {fleshOnly === 'false' && <>
            <input type="radio" name="viewtype" value="moulds"
                  id="toggle-moulds"
                  onChange={handleView} checked={viewtype === 'moulds'}/>
            <label htmlFor="toggle-moulds">Moulds</label>
          </>}

          <input type="radio" name="viewtype" value="missing"
                 id="toggle-missing"
                 onChange={handleView} checked={viewtype === 'missing'}/>
          <label htmlFor="toggle-missing">Missing</label>

          <input type="radio" name="viewtype" value="stats"
                 id="toggle-stats"
                 onChange={handleView} checked={viewtype === 'stats'}/>
          <label htmlFor="toggle-stats">Stats</label>

          <input type="radio" name="viewtype" value="options"
                 id="toggle-options"
                 onChange={handleView} checked={viewtype === 'options'}/>
          <label htmlFor="toggle-options">Options</label>

          <input type="radio" name="viewtype" value="credits"
                 id="toggle-credits"
                 onChange={handleView} checked={viewtype === 'credits'}/>
          <label htmlFor="toggle-credits">Credits</label>
        </div>
        {allowEdit() &&
          <aside id="edit-toggler" className={`${rightNavView && 'edit-toggler-display'}`}>
            <input type="checkbox" id="toggle-edit" checked={!editing} onChange={handleEdit}/>
            <label htmlFor="toggle-edit" title="Toggle Edit Mode"></label>
            {/* {editing ? 'EDITING' : 'EDIT'} */}
          </aside>
        }
      </nav>

    </main>
    </>
  );
}

export default App;
