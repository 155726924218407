import React, { useRef } from 'react';
import html2canvas from 'html2canvas';
import { chojin } from '../../assets/chojin';
import { getDudeImg, handleMyDudes } from '../../assets/utils';

function MissingFiguresComponent({myDudes, setMyDudes, editing, isFleshOnly}) {

    const figs = chojin,
          divRef = useRef(null);

    const createMissingPoster = () => {
        html2canvas(divRef.current).then(canvas => {
            const link = document.createElement('a'),
                  date = new Date().toJSON().slice(0, 10);
            link.download = `my-missing-muscle-dudes-${date}.png`;
            link.href = canvas.toDataURL('image/png');
            link.click();
        });
    }

    return (
        <>
        <h1 style={{ marginBottom: '1rem', width: '100%', textAlign: 'center' }}>
            My Missing Figures
            {isFleshOnly === 'true' && <>
                &nbsp;&nbsp;<button onClick={createMissingPoster}>Export Image</button>
            </>}
        </h1>
        {/* <p>Note: Colors shown are artificial and <em>*not* actual images</em> of each individual figure....unless you can help me obtain them all! ¯\_(ツ)_/¯ </p> */}
        <section id="missing-view" ref={divRef}>
            {figs.map((fig, i) => 
                <article className="moulds-box" key={`${fig.id}-${i}`} style={{marginBottom: '1rem'}}>
                    {/* <h2>#{fig.id} {fig.name}</h2> */}
                    <div className='dFlex'>
                    {fig.colors.map((color, i) => <>
                        {(isFleshOnly === 'false' || color.color === 'Flesh') &&
                            <div className="fig-mould" key={`${fig.id}-${i}`}>
                                {(color.exists && !myDudes[`${fig.id}-${color.class}-${color.color.replace(' ', '')}`]) && 
                                    <>
                                        <figure>
                                            <img src={getDudeImg(fig.id)} alt={`${fig.id}${color.color}`} className={`fig-color-${color.color.replace(' ', '')}`}/>
                                            <figcaption>
                                                <label>
                                                    <input type="checkbox"
                                                        id={`${fig.id}-${color.class}-${color.color.replace(' ', '')}`}
                                                        name={`${fig.id}-${color.class}-${color.color.replace(' ', '')}`}
                                                        checked={myDudes[`${fig.id}-${color.class}-${color.color.replace(' ', '')}`] || false}
                                                        onChange={() => handleMyDudes(`${fig.id}-${color.class}-${color.color.replace(' ', '')}`, setMyDudes)}
                                                        disabled={editing}/>
                                                    #{fig.id} {color.color}
                                                </label>
                                            </figcaption>
                                        </figure>
                                        {(color.class === 'A' || color.class === 'B') &&
                                            <span className={color.class === 'A' ? 'class-badge gold-star' : 'class-badge'}
                                                title={`Brian Bonanno Guide: Class ${color.class} figure`} >
                                                {color.class}
                                            </span>
                                        }

                                    </>
                                }
                            </div>
}
                        </>
                    )}
                    </div>
                </article>
            )}
        </section>
        </>
    );
}

export default MissingFiguresComponent;