import React from 'react';

function CreditsComponent() {

  return (
    <>
    <section id="credits-box">
        <article className="credit-article">
            <figure>
                <img src="images/yudetamago.png" alt="Yudetamago"/>
            </figure>
            <div>
                <h1>First and Foremost</h1>
                <p>
                    Yudetamago (ゆでたまご, "boiled egg") the uber duo also known as writer Takashi Shimada, and artist Yoshinori Nakai.<br/>
                    To them we thank for the Kinnikuman imagination that made the wrestler based character line that M.U.S.C.L.E. was birthed from.<br/>
                    If you guys happen to read this, you rock.
                </p>
            </div>
        </article>
        <article className="credit-article">
            <figure>
                <img src="images/figures/001.png" alt=""/>
            </figure>
            <div>
                <h1>Collector Credits Due</h1>
                <p>
                Most of the organizing, identifying, images, research, and basically loads of contributed hobby hours to the M.U.S.C.L.E.<br/>
                <strong>These are some folks deserving recognition (that I even know of, I'm sure there's more) in (almost) alphabetical order.</strong>
                </p>
            </div>
        </article>
        <article className="credit-article">
            <figure>
                <img src="images/barretto-muscle-manager.png" alt="muscle manager pro"/>
            </figure>
            <div>
                <h2>Chris Barretto</h2>
                <p>
                    The creator of <a href="https://musclemanager.pro" target="_blank" rel="noreferrer">M.U.S.C.L.E. Manager Pro</a> a handy figure
                    manager he put together going for a fleshy/pink set. Every image used to display figures that you see here were provided by him, because he's awesome.
                </p>
            </div>
        </article>
        <article className="credit-article">
            <figure>
                <img src="images/bonanno-muscle-checklist.png" alt="MUSCLE Master Checklist"/>
            </figure>
            <div>
                <h2>Brian Bonanno</h2>
                <p>
                    Brian created the recognized "The Complete M.U.S.C.L.E. Master Checklist" that introduced an A,B,C ranking system
                    to help collectors navigate their collections. His checklist has helped countless collectors over the years
                    and his ranking system is reflected within this app in various areas and ways.<br/>
                    <i>I still have this printed in a five-star 😁</i>
                </p>
            </div>
        </article>
        <article className="credit-article">
            <figure>
                <img src="images/demars-muscle-guide.png" alt="MUSCLE Collector Guide"/>
            </figure>
            <div>
                <h2>Brian DeMars</h2>
                <p>
                    As a known collector with creative &amp; cool displays, Brian is a plethora of knowledge in the form of Tree & Part guides. His efforts have helped collectors approach
                    their collecting strategy in a different manner that helps identify rarity in different colors and regions.
                </p>
            </div>
        </article>
        <article className="credit-article">
            <figure>
                <img src="images/muscle-collectors-fb.png" alt="MUSCLE Collectors Facebook"/>
            </figure>
            <div>
                <h2>Tim Hiser</h2>
                <p>
                    Tim has moderated the largest M.U.S.C.L.E. Collectors facebook group for years and provided both a great environment for other colllectors
                    to interact but also a plethora of education into the manga lore of every character with his "Tell-All Tuesday" articles and other resources.
                </p>
            </div>
        </article>
        <article className="credit-article">
            <figure>
                <img src="images/soupies-muscle.png" alt="Soupies"/>
            </figure>
            <div>
                <h2>Soupie's M.U.S.C.L.E. Archive</h2>
                <p>
                    Real identity unknown, but <a href="http://soupie.littlerubberguys.com/mcia/MUSCLECIA.html" target="_blank" rel="noreferrer">Soupie's Archive</a>
                    provides a great resource for real images and Tree &amp; Part collecting guide for collectors.
                </p>
            </div>
        </article>
        <article className="credit-article">
            <figure>
                <img src="images/uofmuscle.png" alt="University of MUSCLE"/>
            </figure>
            <div>
                <h2>UofMuscle.com</h2>
                <p>
                    Many have used <a href="http://uofmuscle.com" target="_blank" rel="noreferrer">uofmuscle.com</a> as a valuable resource over the years. Where the collection
                    of set images, manga lore, and other resources has been a gold standard for collectors for a long time. While I'm not sure who exactly to
                    attribute to this site and its resources, it's worth noting where credit is due.
                </p>
            </div>
        </article>
        <article className="credit-article">
            <figure>
                <img src="images/figures/003.png" alt="CW"/>
            </figure>
            <div>
                <h2>Chris Whittier</h2>
                <p>
                    A collector fan dude that created the thing you're using/reading. I basically picked collecting back up in mid 2024 still before knowing
                    even the basics like the Kinnikuman connection etc. I collected for awhile merely by going off my original poster for reference and the ol'
                    "I don't think I have this color" method of collecting decades ago. Then when I found my old stashes in storage picked it back up and have been
                    enjoying both the community and the hunt towards a "master" set ever since!<br/>
                    If you happen to have some figures I need or just want to chat hollar @ chris at professionalsweb dot com, with professionalsweb one word.
                </p>
            </div>
        </article>
    </section>
    </>
  );
}

export default CreditsComponent;