import React from 'react';
import { chojin } from '../../assets/chojin';
import { boxSetString, getDudeImg } from '../../assets/utils';

function PackagesViewComponent() {

  const packageOrg = (set) => {
    let setBox1 = [],
        setBox2 = [],
        setBox3 = [],
        setBox4 = [];

    for (let i = 0, x = chojin.length; i<x; i++) {
      switch (chojin[i]?.set_box) {
        case 1:
          setBox1.push(chojin[i]);
          break;
        case 2:
          setBox2.push(chojin[i]);
          break;
        case 3:
          setBox3.push(chojin[i]);
          break;
        case 4:
          setBox4.push(chojin[i]);
          break;
        default:
          // console.log(`not a box fig`);
      }
    }
    return (<>
    <nav className="quick-links">
      <a href="#box-set-1">{boxSetString(setBox1[0].set_box)}</a> | 
      <a href="#box-set-2">{boxSetString(setBox2[0].set_box)}</a> | 
      <a href="#box-set-3">{boxSetString(setBox3[0].set_box)}</a> | 
      <a href="#box-set-4">{boxSetString(setBox4[0].set_box)}</a>
    </nav>
      <section className="chojin-setview">
        <article className="box-set-collection">
          <h1 className={`box-title set-box-1-bg`} id="box-set-1">Box {boxSetString(setBox1[0].set_box)}</h1>
          <div className="box-view set-box-1-bg">
            {setBox1.map((dude, index) =>
              <div className='set-box-card' key={`dude-box1-${dude.id}`} data-id={`${dude.id}`}>
                <figure>
                  <img src={getDudeImg(dude.id)} alt={dude.name}/>
                  <figcaption>{dude.name}</figcaption>
                </figure>         
              </div>
            )}
          </div>
        </article>

        <article className="box-set-collection">
          <h1 className="box-title set-box-2-bg" id="box-set-2">Box {boxSetString(setBox2[0].set_box)}</h1>
          <div className="box-view set-box-2-bg">
            {setBox2.map((dude, index) =>
              <div className='set-box-card' key={`dude-box2-${dude.id}`} data-id={`${dude.id}`}>
                <figure>
                  <img src={getDudeImg(dude.id)} alt={dude.name}/>
                  <figcaption>{dude.name}</figcaption>
                </figure>          
              </div>
            )}
          </div>
        </article>

        <article className="box-set-collection">
          <h1 className="box-title set-box-3-bg" id="box-set-3">Box {boxSetString(setBox3[0].set_box)}</h1>
          <div className="box-view set-box-3-bg">
            {setBox3.map((dude, index) =>
              <div className='set-box-card' key={`dude-box3-${dude.id}`} data-id={`${dude.id}`}>
                <figure>
                  <img src={getDudeImg(dude.id)} alt={dude.name}/>
                  <figcaption>{dude.name}</figcaption>
                </figure>          
              </div>
            )}
          </div>
        </article>

        <article className="box-set-collection">
          <h1 className="box-title set-box-4-bg" id="box-set-4">Box {boxSetString(setBox4[0].set_box)}</h1>
          <div className="box-view set-box-4-bg">
            {setBox4.map((dude, index) =>
              <div className='set-box-card' key={`dude-box4-${dude.id}`} data-id={`${dude.id}`}>
                <figure>
                  <img src={getDudeImg(dude.id)} alt={dude.name}/>
                  <figcaption>{dude.name}</figcaption>
                </figure>          
              </div>
            )}
          </div>
        </article>
      </section>
    </>)
  }

  return (
    <>
      {packageOrg()}
    </>
  );
}

export default PackagesViewComponent;