import React from 'react';
import { chojin } from '../../assets/chojin';
import { boxSetString, getDudeImg, imgUrl, getColorClass, handleMyDudes } from '../../assets/utils';

function ListViewComponent({myDudes, setMyDudes, editing, isFleshOnly}) {

    const ifColorExists = (exists, color) => {
        return exists ? { title: color } : '';
    }

    return (
        <>
        <table id="chojin-listview">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Figure</th>
                    <th>Name</th>
                    <th>Flesh</th>
                    {isFleshOnly === 'false' &&
                        <>
                            <th>D. Blue</th>
                            <th>L. Blue</th>
                            <th>Green</th>
                            <th>Red</th>
                            <th>Salmon</th>
                            <th>Orange</th>
                            <th>Purple</th>
                            <th>Grape</th>
                            <th>Magenta</th>
                        </>
                    }
                    <th>Found In</th>
                    <th>Tree #</th>
                    <th>Part #</th>
                </tr>
            </thead>
            <tbody>
                {chojin.map((dude, i) =>
                    <tr key={`${dude.id}-${i}`}>
                        <td>{dude.id}</td>
                        <td>
                            <img src={getDudeImg(dude.id)} alt={dude.name}/>
                        </td>
                        <td className="allow-text-break">{dude.name}</td>

                        {dude.colors.map((color, i) => <>
                            {(isFleshOnly === 'false' || color.color === 'Flesh') &&
                                <td className={`list-color-box ${color.exists ? getColorClass(color.color) : ''}`}
                                    key={`dude-${dude.id}-box-${i}`}
                                    {...ifColorExists(color.exists, color.color)}>
                                    {color.exists &&
                                        <>
                                                <input type="checkbox"
                                                id={`${dude.id}-${color.class}-${color.color.replace(' ', '')}`}
                                                name={`${dude.id}-${color.class}-${color.color.replace(' ', '')}`}
                                                checked={myDudes[`${dude.id}-${color.class}-${color.color.replace(' ', '')}`] || false}
                                                onChange={() => handleMyDudes(`${dude.id}-${color.class}-${color.color.replace(' ', '')}`, setMyDudes)}
                                                disabled={editing}/>
                                                {/* <label for={`dude-${dude.id}-${colorStr}`}>{getColorAbbr(color.color)}</label> */}
                                            {(color.class === 'A' || color.class === 'B') && 
                                                <>
                                                    <span className={color.class === 'A' ? 'class-badge gold-star' : 'class-badge'}
                                                            title={`Brian Bonanno Guide: Class ${color.class} figure`} >
                                                        {color.class}
                                                    </span>
                                                </>
                                            }
                                        </>
                                    }
                                </td>
                            }
                            </>
                        )}

                        <td>
                            <div className="list-packs-box">
                                {(dude.id === 234 || dude.id === 235) &&
                                    <span>Ring Figure</span>
                                }
                                {(dude.id === 236) &&
                                    <span>Non-Poster Figure</span>
                                }
                                {dude.packs?.map((pack, i) => (

                                    <div className="dudePacks" key={`dude-${dude.id}-pack-${i}`}>
                                        {pack === 28 && 
                                            <>
                                            <img src={imgUrl + '28-pack.svg'} alt="28 Pack" title="28 Pack"/>
                                            <span>28 Pack</span>
                                            </>
                                        }
                                        {pack === 10 && 
                                            <>
                                            <img src={imgUrl + '10-pack.svg'} alt="10 Pack" title="10 Pack"/>
                                            <span>10 Pack</span>
                                            </>
                                        }
                                        {pack === 4 &&
                                            <>
                                            <img src={imgUrl + '4-pack.svg'} alt="4 Pack" title="4 Pack"/>
                                            <span>4 Pack</span>
                                            </>
                                        }
                                    </div>
                                    
                                ))}
                                {dude.set_box &&
                                    <>
                                    <div className={`set-box-${dude.set_box}-bg`} key={`${dude.id}-set-box`}>Set:<br/>{boxSetString(dude.set_box)}</div>
                                    </>
                                }
                            </div>
                        </td>
                        <td title={`Tree #${dude.tree}`}>
                            {dude.tree}
                        </td>
                        <td title={`Part #${dude.part}`}>
                            {dude.part}
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </>
    );
}

export default ListViewComponent;