import React from 'react';
import { chojin } from '../../assets/chojin';
import { boxSetString, getColorClass, getColorAbbr, getDudeImg, getFigColors, imgUrl, handleMyDudes } from '../../assets/utils';

function ShowcaseComponent({myDudes, setMyDudes, editing, isFleshOnly}) {

  let roster = [];

  const createCard = (dude) => {
    let picUrl = getDudeImg(dude.id),
        hasColors = [];

    hasColors = getFigColors(dude);

    const figColors = (
      <ul className="color-badges">
        {hasColors.map((color, i) =>
          <>
          {(isFleshOnly === 'false' || color.color === 'Flesh') &&
            <li className={getColorClass(color.color)} key={`${dude.id}-${color.class}-${color.color}-${i}`} title={color.color}>
              <input type="checkbox"
                id={`${dude.id}-${color.class}-${color.color.replace(' ', '')}`}
                name={`${dude.id}-${color.class}-${color.color.replace(' ', '')}`}
                checked={myDudes[`${dude.id}-${color.class}-${color.color.replace(' ', '')}`] || false}
                onChange={() => handleMyDudes(`${dude.id}-${color.class}-${color.color.replace(' ', '')}`, setMyDudes)}
                disabled={editing}/>
              {getColorAbbr(color.color)} {(color.class === 'A' || color.class === 'B') && 
                <><span className={color.class === 'A' ? 'class-badge gold-star' : 'class-badge'} title={`Brian Bonanno Guide: Class ${color.class} figure`} >{color.class}</span></>
              }
            </li>
          }
          </>
        )}
      </ul>
    );

    return (
      <article className="chojin-card" key={ `chojin-${dude.id}` }>
        <aside className="chojin-collection-info">
          <div className="chojin-label chojin-number">{dude.id}</div>
          <ol className="chojin-mfg" title="Part Tree / Guide Classifications">
            <li>Part #{dude.part}</li>
            <li>Tree #{dude.tree}</li>
          </ol>
        </aside>
        
        <div id={`dude-id${dude.id}`}>
          <figure>
            <img src={picUrl} alt={dude.name}/>
            <figcaption className="chojin-label">
              {dude.name}
            </figcaption>
          </figure>
          <div className="chojin-info">
            {dude.aka_name &&
              <>AKA: "{dude.aka_name}"<hr/></>
            }
            {dude.set_box &&
              <>
                <div className={`set-box-${dude.set_box}-bg`}>Set: {boxSetString(dude.set_box)}</div>
              </>
            }
            {dude.packs?.map((pack) => (
              <div className="dudePacks" key={`dude-${dude.id}-pack-${pack}`}>
                {pack === 28 && 
                  <>
                  <img src={imgUrl + '28-pack.svg'} alt="28 Pack" title="28 Pack"/>
                  <span>28 Pack</span>
                  </>
                }
                {pack === 10 && 
                  <>
                  <img src={imgUrl + '10-pack.svg'} alt="10 Pack" title="10 Pack"/>
                  <span>10 Pack</span>
                  </>
                }
                {pack === 4 &&
                  <>
                  <img src={imgUrl + '4-pack.svg'} alt="4 Pack" title="4 Pack"/>
                  <span>4 Pack</span>
                  </>
                }
              </div>

            ))}
          </div>
        </div>
        {figColors}
      </article>
    );
  }

  chojin.forEach((dude) => {
    roster.push(createCard(dude));
  });
  
  return (
    <>
      {roster}
    </>
  );
}

export default ShowcaseComponent;