export const chojin = [
    {
        id: 1,
        name: 'Muscle Man',
        aka_name: 'Kinnikuman',
        packs: [4, 10],
        set_box: null,
        part: 8,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'has-cape',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'B'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'A'
            },
            {
                color: 'Grape',
                exists: true,
                class: 'C'
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 2,
        name: 'Terri-Bull',
        aka_name: 'Buffaloman',
        packs: [4, 10],
        set_box: null,
        part: 7,
        tree: 4,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: null,
        accessories_mythical: 'has-horns',
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'A'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 3,
        name: 'Sunshine (C)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 8,
        tree: 3,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-mixed',
        torso: 'not-humanoid',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: 'naked',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'A'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 4,
        name: 'Kani Besu',
        aka_name: '',
        packs: [28],
        set_box: 4,
        part: 3,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'not-hands',
        torso: 'not-humanoid',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'naked',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 5,
        name: 'Planet Man',
        aka_name: '',
        packs: [28],
        set_box: 3,
        part: 4,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-open',
        torso: 'not-humanoid',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 6,
        name: 'Erimaki Tokage',
        aka_name: '',
        packs: [28],
        set_box: 3,
        part: 4,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-open',
        torso: 'not-humanoid',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 7,
        name: 'Puyo Puyo',
        aka_name: '',
        packs: [28],
        set_box: 4,
        part: 3,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-open',
        torso: 'not-humanoid',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'naked',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 8,
        name: 'Shishikababu',
        aka_name: '',
        packs: [28],
        set_box: 4,
        part: 3,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: 'has-shirt',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 9,
        name: 'Wakusei Barukan',
        aka_name: '',
        packs: [28],
        set_box: 4,
        part: 9,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-mixed',
        torso: 'not-humanoid',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 10,
        name: 'Jessie Maybia',
        aka_name: '',
        packs: [28],
        set_box: 1,
        part: 2,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'A'
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 11,
        name: 'Hidora King',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 21,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'A'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'B'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 12,
        name: 'Oil Man',
        aka_name: '',
        packs: [28],
        set_box: 3,
        part: 4,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'not-hands',
        torso: 'not-humanoid',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'naked',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 13,
        name: 'King Cobra',
        aka_name: '',
        packs: [28],
        set_box: 1,
        part: 2,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-open',
        torso: 'torso-skinny',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: 'has-shirt',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'A'
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 14,
        name: 'Robin Mask',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 13,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 15,
        name: 'Sunshine (D)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 13,
        tree: 2,
        arms: 'no-arms',
        arms_y: null,
        hands: null,
        torso: 'not-humanoid',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: 'naked',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'A'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 16,
        name: 'Neptuneman (B)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 13,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'A'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 17,
        name: 'Kinnikuman (B)',
        aka_name: '',
        packs: [28],
        set_box: 1,
        part: 2,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'has-cape',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'A'
            }
        ]
    },
    {
        id: 18,
        name: 'Daiyaman',
        aka_name: '',
        packs: [28],
        set_box: 2,
        part: 7,
        tree: 3,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-closed',
        torso: 'not-humanoid',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'has-helmet',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'A'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 19,
        name: 'Black Killer',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 9,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'not-hands',
        torso: 'torso-muscular',
        accessories: 'has-weapon',
        accessories_mythical: null,
        accessories_wardrobe: 'has-helmet',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'A'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'A'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 20,
        name: 'Kinniku Daiou',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 1,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-closed',
        torso: 'torso-chubby',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: 'has-shirt',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'A'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 21,
        name: 'Akuma Shogun (A)',
        aka_name: '',
        packs: [28],
        set_box: 1,
        part: 2,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: 'has-horns',
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'A'
            }
        ]
    },
    {
        id: 22,
        name: 'The Manriki',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 21,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'A'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'B'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 23,
        name: 'Iwao',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 1,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-closed',
        torso: 'not-humanoid',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'naked',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'A'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 24,
        name: 'Cubeman',
        aka_name: '',
        packs: [28],
        set_box: 1,
        part: 2,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-closed',
        torso: 'not-humanoid',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'has-pants',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'A'
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 25,
        name: 'Prince Kamehame',
        aka_name: '',
        packs: [28],
        set_box: 1,
        part: 2,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: 'has-pants',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'A'
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 26,
        name: 'The Ninja (A)',
        aka_name: '',
        packs: [28],
        set_box: 1,
        part: 2,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: 'has-shirt',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'A'
            }
        ]
    },
    {
        id: 27,
        name: 'Ashuraman (A)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 1,
        tree: 1,
        arms: 'multi-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 28,
        name: 'John Stimboard',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 1,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'clothed',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 29,
        name: 'Terryman (C)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 13,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: 'clothed',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'A'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 30,
        name: 'Keyman',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 13,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-open',
        torso: 'not-humanoid',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'naked',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 31,
        name: 'Terryman (A)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 1,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'A'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 32,
        name: 'Kington',
        aka_name: '',
        packs: [28],
        set_box: 4,
        part: 12,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'not-hands',
        torso: 'torso-chubby',
        accessories: 'has-helmet',
        accessories_mythical: 'has-horns',
        accessories_wardrobe: 'clothed',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 33,
        name: 'Skull Bozu',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 1,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-mixed',
        torso: 'torso-skinny',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'A'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 34,
        name: 'Nokogira Man',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 13,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 35,
        name: 'Warsman (A)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 1,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-closed',
        torso: 'torso-skinny',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'A'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 36,
        name: 'Kinkotsuman',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 1,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-open',
        torso: 'torso-skinny',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'clothed',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'A'
            },
            {
                color: 'Green',
                exists: true,
                class: 'B'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 37,
        name: 'Dynamite Piper',
        aka_name: '',
        packs: [28],
        set_box: 4,
        part: 9,
        tree: 4,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 38,
        name: 'Tileman',
        aka_name: '',
        packs: [28],
        set_box: 1,
        part: 2,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-closed',
        torso: 'not-humanoid',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'naked',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'A'
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 39,
        name: 'Sunshine (A)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 1,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-open',
        torso: 'not-humanoid',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: 'naked',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 40,
        name: 'Kendaman (B)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 13,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'A'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'A'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 41,
        name: 'Beauty Rhodes',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 1,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-closed',
        torso: 'torso-chubby',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'A'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 42,
        name: 'Goremuman',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 17,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'A'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 43,
        name: 'Curry Cook',
        aka_name: '',
        packs: [28],
        set_box: 1,
        part: 2,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-mixed',
        torso: 'torso-skinny',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: 'has-pants',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'A'
            }
        ]
    },
    {
        id: 44,
        name: 'Benkimen (A)',
        aka_name: '',
        packs: [28],
        set_box: 1,
        part: 2,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-mixed',
        torso: 'not-humanoid',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: 'naked',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'A'
            }
        ]
    },
    {
        id: 45,
        name: 'Springman',
        aka_name: '',
        packs: [28],
        set_box: 2,
        part: 5,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-mixed',
        torso: 'not-humanoid',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'naked',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 46,
        name: 'Robin Mask (A)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 1,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'A'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 47,
        name: 'Linchou',
        aka_name: '',
        packs: [28],
        set_box: 2,
        part: 7,
        tree: 3,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-mixed',
        torso: 'torso-chubby',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: 'has-pants',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'A'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 48,
        name: 'Teapack Man',
        aka_name: '',
        packs: [28],
        set_box: 1,
        part: 2,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-mixed',
        torso: 'not-humanoid',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'A'
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 49,
        name: 'Black Shadow',
        aka_name: '',
        packs: [28],
        set_box: 1,
        part: 2,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: 'has-horns',
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'A'
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 50,
        name: 'Hawkman (A)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 16,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: 'has-wings',
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Green',
                exists: true,
                class: 'A'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 51,
        name: 'Canadianman',
        aka_name: '',
        packs: [28],
        set_box: 1,
        part: 2,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'clothed',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'A'
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 52,
        name: 'Jawsman',
        aka_name: '',
        packs: [28],
        set_box: 3,
        part: 4,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 53,
        name: 'Junkman (A)',
        aka_name: '',
        packs: [28],
        set_box: 1,
        part: 2,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'not-hands',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'A'
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 54,
        name: 'The Mountain',
        aka_name: '',
        packs: [28],
        set_box: 1,
        part: 2,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-open',
        torso: 'not-humanoid',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'has-shirt',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'A'
            }
        ]
    },
    {
        id: 55,
        name: 'Skyman',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 1,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 56,
        name: 'Neptune King',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 13,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-closed',
        torso: 'torso-chubby',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'has-pants',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'A'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 57,
        name: 'Kinnikuman Great',
        aka_name: '',
        packs: [28],
        set_box: 3,
        part: 4,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'clothed',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 58,
        name: 'Buffaloman',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 13,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: 'has-horns',
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 59,
        name: 'Warsman (B)',
        aka_name: '',
        packs: [28],
        set_box: 3,
        part: 4,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 60,
        name: 'Mito (A)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 1,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-open',
        torso: 'torso-chubby',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'has-shirt',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'A'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'A'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'A'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 61,
        name: 'Kinnikuman (A)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 1,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'A'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 62,
        name: 'The Sheik',
        aka_name: '',
        packs: [28],
        set_box: 4,
        part: 3,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-mixed',
        torso: 'torso-skinny',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: 'has-pants',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 63,
        name: 'Goriki',
        aka_name: '',
        packs: [28],
        set_box: 3,
        part: 4,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 64,
        name: 'Sunshine (B)',
        aka_name: '',
        packs: [28],
        set_box: 1,
        part: 5,
        tree: 3,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-mixed',
        torso: 'not-humanoid',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'naked',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'A'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 65,
        name: 'Crystalman',
        aka_name: '',
        packs: [28],
        set_box: 4,
        part: 3,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 66,
        name: 'Mr. Barracuda (A)',
        aka_name: '',
        packs: [28],
        set_box: 4,
        part: 3,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'clothed',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 67,
        name: 'Pentagon',
        aka_name: '',
        packs: [28],
        set_box: 1,
        part: 2,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: 'has-wings',
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'A'
            }
        ]
    },
    {
        id: 68,
        name: 'Okutopasu Dragon',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 11,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: null,
        accessories_mythical: 'has-horns',
        accessories_wardrobe: 'has-cape',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'A'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 69,
        name: 'Specialman',
        aka_name: '',
        packs: [28],
        set_box: 4,
        part: 3,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'clothed',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 70,
        name: 'Ashuraman (B)',
        aka_name: '',
        packs: [28],
        set_box: 1,
        part: 6,
        tree: 1,
        arms: 'multi-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 71,
        name: 'Neptuneman (A)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 5,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'A'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 72,
        name: 'Sunigator (A)',
        aka_name: '',
        packs: [28],
        set_box: 1,
        part: 5,
        tree: 3,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-open',
        torso: 'not-humanoid',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'A'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 73,
        name: 'Mairudoman',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 5,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'has-helment',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'A'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 74,
        name: 'Spray Majin',
        aka_name: '',
        packs: [28],
        set_box: 3,
        part: 4,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-closed',
        torso: 'not-humanoid',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'naked',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 75,
        name: 'Abdullah',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 5,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-open',
        torso: 'torso-chubby',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: 'has-pants',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'A'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 76,
        name: 'Eraginesu',
        aka_name: '',
        packs: [28],
        set_box: 1,
        part: 5,
        tree: 3,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-open',
        torso: 'not-humanoid',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'A'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 77,
        name: 'Ashuraman No Sensei',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 14,
        tree: 1,
        arms: 'multi-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-mixed',
        torso: 'torso-chubby',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: 'clothed',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'B'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'A'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'A'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 78,
        name: 'Vikingman',
        aka_name: '',
        packs: [28],
        set_box: 1,
        part: 6,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: 'has-horns',
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 79,
        name: 'Hammerhead',
        packs: [28],
        set_box: 2,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 80,
        name: 'Paper Miira',
        aka_name: '',
        packs: [28],
        set_box: 3,
        part: 4,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-open',
        torso: 'not-humanoid',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'naked',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 81,
        name: 'Amelman',
        packs: [28],
        set_box: 2,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 82,
        name: 'Ramenman (A)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 1,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-open',
        torso: 'torso-skinny',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'clothed',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'A'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 83,
        name: 'Hang Killer',
        aka_name: '',
        packs: [28],
        set_box: 2,
        part: 5,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 84,
        name: 'Snakeman',
        aka_name: '',
        packs: [28],
        set_box: 1,
        part: 7,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-open',
        torso: 'not-humanoid',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 85,
        name: 'Kinnikuman Zebra (A)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 14,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'naked',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Green',
                exists: true,
                class: 'B'
            },
            {
                color: 'Red',
                exists: true,
                class: 'A'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'A'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 86,
        name: 'De Ballman',
        aka_name: '',
        packs: [28],
        set_box: 2,
        part: 7,
        tree: 3,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-open',
        torso: 'torso-skinny',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'A'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 87,
        name: 'Magnitude Ichiman',
        aka_name: '',
        packs: [28],
        set_box: 3,
        part: 7,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 88,
        name: 'Armstrong',
        aka_name: '',
        packs: [28],
        set_box: 2,
        part: 5,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 89,
        name: 'Watchman',
        aka_name: '',
        packs: [28],
        set_box: 2,
        part: 5,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-open',
        torso: 'not-humanoid',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'naked',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 90,
        name: 'Combatman',
        aka_name: '',
        packs: [28],
        set_box: 1,
        part: 5,
        tree: 3,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'A'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 91,
        name: 'Cyborg SW',
        aka_name: '',
        packs: [28],
        set_box: 1,
        part: 6,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-mixed',
        torso: 'torso-skinny',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 92,
        name: 'Akuma Kishi',
        aka_name: '',
        packs: [28],
        set_box: 2,
        part: 7,
        tree: 3,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'has-cape',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'A'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 93,
        name: 'Buffaloman (A)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 1,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: 'has-horns',
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'A'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 94,
        name: 'Kintaman',
        aka_name: '',
        packs: [28],
        set_box: 3,
        part: 4,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 95,
        name: 'Big Radial',
        aka_name: '',
        packs: [28],
        set_box: 1,
        part: 7,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-open',
        torso: 'not-humanoid',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 96,
        name: 'The Ninja (B)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 13,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'clothed',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 97,
        name: 'Condora',
        aka_name: '',
        packs: [28],
        set_box: 2,
        part: 7,
        tree: 3,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'unhumanoid',
        accessories_mythical: 'has-wings',
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'A'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 98,
        name: 'Dark Nisei',
        aka_name: '',
        packs: [28],
        set_box: 4,
        part: 8,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 99,
        name: 'The Mari',
        aka_name: '',
        packs: [28],
        set_box: 4,
        part: 8,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-closed',
        torso: 'not-humanoid',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: 'naked',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'B'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 100,
        name: 'Bam Bam Ji',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 7,
        tree: 4,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: 'clothed',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 101,
        name: 'Godo Shisa',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 7,
        tree: 4,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'has-shirt',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 102,
        name: 'Mapman',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 7,
        tree: 4,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'has-cape',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'B'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 103,
        name: 'Black Hole',
        aka_name: '',
        packs: [28],
        set_box: 1,
        part: 2,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'clothed',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'A'
            }
        ]
    },
    {
        id: 104,
        name: 'Buka (B)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 11,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'A'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'A'
            }
        ]
    },
    {
        id: 105,
        name: 'Buffaloman (D)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 15,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: 'has-horns',
        accessories_wardrobe: 'has-shirt',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'B'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 106,
        name: 'Aiansueto Kita Chojin',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 14,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'B'
            },
            {
                color: 'Red',
                exists: true,
                class: 'B'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'A'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'A'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 107,
        name: 'Sunshine (E)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 14,
        tree: 1,
        arms: 'no-arms',
        arms_y: null,
        hands: null,
        torso: 'not-humanoid',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'naked',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'B'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'A'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'A'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 108,
        name: 'Rikishiman (B) Wolfman',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 14,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Green',
                exists: true,
                class: 'B'
            },
            {
                color: 'Red',
                exists: true,
                class: 'A'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'A'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 109,
        name: 'Ashuraman No Chichi',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 14,
        tree: 1,
        arms: 'multi-arms',
        arms_y: 'arms-up',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: 'has-cape',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'B'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'A'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'A'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 110,
        name: 'Kinnikuman (F)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 14,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'hast-shirt',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Green',
                exists: true,
                class: 'B'
            },
            {
                color: 'Red',
                exists: true,
                class: 'A'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'A'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 111,
        name: 'Akuma Shogun (B)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 13,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: 'has-horns',
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 112,
        name: 'Sunigator (D)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 14,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'naked',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Green',
                exists: true,
                class: 'B'
            },
            {
                color: 'Red',
                exists: true,
                class: 'A'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'A'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 113,
        name: 'Super Phoenix (A)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 14,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'clothed',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Green',
                exists: true,
                class: 'B'
            },
            {
                color: 'Red',
                exists: true,
                class: 'A'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'A'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 114,
        name: 'Pandaman',
        aka_name: '',
        packs: [28],
        set_box: 2,
        part: 7,
        tree: 3,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'A'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 115,
        name: 'Kiki Jin',
        aka_name: '',
        packs: [28],
        set_box: 3,
        part: 7,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-closed',
        torso: 'not-humanoid',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 116,
        name: 'Kinnikuman Soldier (A)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 14,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'clothed',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Green',
                exists: true,
                class: 'B'
            },
            {
                color: 'Red',
                exists: true,
                class: 'A'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'A'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 117,
        name: 'Rollerman',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 10,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-open',
        torso: 'no-humanoid',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: 'naked',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 118,
        name: 'ICBM',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 10,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'clothed',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 119,
        name: 'Bermuda III (A)',
        aka_name: '',
        packs: [28],
        set_box: 4,
        part: 12,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'has-shirt',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 120,
        name: 'Sabotenman',
        aka_name: '',
        packs: [28],
        set_box: 2,
        part: 10,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'has-pants',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 121,
        name: 'Hantenguman',
        aka_name: '',
        packs: [28],
        set_box: 4,
        part: 12,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'not-hands',
        torso: 'not-humanoid',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 122,
        name: 'Kinnikuman (C)',
        aka_name: '',
        packs: [28],
        set_box: 4,
        part: 3,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'has-shirt',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 123,
        name: 'Geronimo (A)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 1,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'A'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 124,
        name: 'Foxman',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 10,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 125,
        name: 'Buka (A)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 11,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: 'has-cape',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'A'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'A'
            }
        ]
    },
    {
        id: 126,
        name: 'Kenkaman',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 10,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: 'has-shirt',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 127,
        name: 'Junkman (B)',
        aka_name: '',
        packs: [28],
        set_box: 4,
        part: 12,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'not-hands',
        torso: 'torso-muscular',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'has-weapon',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'B'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 128,
        name: 'Smileman',
        aka_name: '',
        packs: [28],
        set_box: 2,
        part: 10,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'clothed',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 129,
        name: 'Ashuraman (C)',
        aka_name: '',
        packs: [28],
        set_box: 2,
        part: 10,
        tree: 1,
        arms: 'multi-arms',
        arms_y: null,
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 130,
        name: 'Mirion Hell',
        aka_name: '',
        packs: [28],
        set_box: 4,
        part: 3,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'clothed',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 131,
        name: 'Ukon',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 11,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'not-hands',
        torso: 'torso-muscular',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'has-helmet',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'A'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 132,
        name: 'Screw Kid (A)',
        packs: [28],
        set_box: 2,
        part: 15,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 133,
        name: 'Baka (C)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 11,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'A'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 134,
        name: 'Ameragu',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 11,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: 'clothed',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'A'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'A'
            }
        ]
    },
    {
        id: 135,
        name: 'Kyoaku Chojin (B)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 11,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'not-hands',
        torso: 'torso-muscular',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'has-weapon',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'A'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'A'
            }
        ]
    },
    {
        id: 136,
        name: 'Harigorasu',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 11,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'A'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'A'
            }
        ]
    },
    {
        id: 137,
        name: 'Bermuda III (C)',
        aka_name: '',
        packs: [28],
        set_box: 4,
        part: 12,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'clothed',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 138,
        name: 'Personalman',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 8,
        tree: 3,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'not-hands',
        torso: 'not-humanoid',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'naked',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'B'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'A'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 139,
        name: 'MokoSeijin',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 11,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'A'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 140,
        name: 'Terryman (B)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 11,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'A'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 141,
        name: 'Kinnikuman (E)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 10,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 142,
        name: 'Bermuda III (B)',
        aka_name: '',
        packs: [28],
        set_box: 4,
        part: 12,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'has-shirt',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 143,
        name: 'Black Night',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 9,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: 'has-weapon',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'A'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'A'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 144,
        name: 'Mr. America',
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 145,
        name: 'Black Tomahawk',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 9,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'has-weapon',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'A'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'A'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 146,
        name: 'Playerman',
        aka_name: '',
        packs: [28],
        set_box: 4,
        part: 9,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'not-hands',
        torso: 'not-humanoid',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'has-weapon',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 147,
        name: 'Iron Commando',
        aka_name: '',
        packs: [28],
        set_box: 4,
        part: 9,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 148,
        name: 'Furashya Balloon',
        aka_name: '',
        packs: [28],
        set_box: 4,
        part: 8,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'B'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 149,
        name: 'Screw Kid (B)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 8,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'not-hands',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: 'has-weapon',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'B'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'A'
            },
            {
                color: 'Grape',
                exists: true,
                class: 'C'
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 150,
        name: 'Apollo The Giant',
        aka_name: '',
        packs: [28],
        set_box: 4,
        part: 8,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'not-hands',
        torso: 'torso-chubby',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: 'clothed',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'B'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 151,
        name: 'Kanderaman',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 8,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'clothed',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'B'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'A'
            },
            {
                color: 'Grape',
                exists: true,
                class: 'C'
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 152,
        name: 'Mongolman (B)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 8,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'has-pants',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'B'
            },
            {
                color: 'Red',
                exists: true,
                class: 'B'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'A'
            },
            {
                color: 'Grape',
                exists: true,
                class: 'C'
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 153,
        name: 'Sunigator Shotai',
        aka_name: 'The Claw',
        packs: [4, 10],
        set_box: null,
        part: 8,
        tree: 3,
        arms: 'no-arms',
        arms_y: 'arms-mid',
        hands: 'not-hands',
        torso: 'not-humanoid',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'naked',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'B'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'A'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 154,
        name: 'Anmonaitosu',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 8,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'B'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'A'
            },
            {
                color: 'Grape',
                exists: true,
                class: 'C'
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 155,
        name: 'Shyaneruman',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 1,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'clothed',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'B'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'A'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 156,
        name: 'Wolfman (A)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 1,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'A'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'A'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 157,
        name: 'Black King',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 9,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'both-accessories',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'A'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'A'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 158,
        name: 'Bazookara',
        aka_name: '',
        packs: [28],
        set_box: 4,
        part: 9,
        tree: 4,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-closed',
        torso: 'torso-cubby',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'naked',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 159,
        name: 'Mr. Akiresu',
        aka_name: 'Mr. Achilles',
        packs: [28],
        set_box: 4,
        part: 9,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'has-shirt',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 160,
        name: 'Chavo Kerori',
        aka_name: '',
        packs: [28],
        set_box: 4,
        part: 9,
        tree: 4,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 161,
        name: 'Atlantis',
        aka_name: '',
        packs: [28],
        set_box: 1,
        part: 1,
        tree: 3,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'A'
            }
        ]
    },
    {
        id: 162,
        name: 'Nakano-San',
        aka_name: 'The Announcer',
        packs: [28],
        set_box: 4,
        part: 9,
        tree: 4,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-closed',
        torso: 'torso-chubby',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: 'clothed',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 163,
        name: 'Mammothman',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 16,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Green',
                exists: true,
                class: 'A'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 164,
        name: 'Kinnikuman Big Body (A)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 16,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Green',
                exists: true,
                class: 'A'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 165,
        name: 'Black Zumou',
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'A'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'A'
            }
        ]
    },
    {
        id: 166,
        name: 'Kinnikuman Mariposa (A)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 16,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'clothed',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Green',
                exists: true,
                class: 'A'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 167,
        name: 'Black Rain',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 15,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'A'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'A'
            }
        ]
    },
    {
        id: 168,
        name: 'Mr. VTR',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 16,
        tree: 1,
        arms: 'not-arms',
        arms_y: 'arms-down',
        hands: 'hands-mixed',
        torso: 'not-humanoid',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'naked',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'A'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Green',
                exists: true,
                class: 'A'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 169,
        name: 'Benkiman (B)',
        aka_name: '',
        packs: [28],
        set_box: 3,
        part: 17,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-mixed',
        torso: 'not-humanoid',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'has-weapon',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 170,
        name: 'The Hunter',
        aka_name: '',
        packs: [28],
        set_box: 3,
        part: 17,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 171,
        name: 'The Mixer Taite (A)',
        aka_name: '',
        packs: [28],
        set_box: 3,
        part: 17,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-closed',
        torso: 'not-humanoid',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 172,
        name: 'Terryman (D)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 17,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Green',
                exists: true,
                class: 'A'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 173,
        name: 'Mr. Kamen (B)',
        aka_name: '',
        packs: [28],
        set_box: 3,
        part: 17,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 174,
        name: 'Ashuraman (E)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 17,
        tree: 1,
        arms: 'multi-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Green',
                exists: true,
                class: 'A'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 175,
        name: 'Lepaurudon',
        aka_name: '',
        packs: [28],
        set_box: 3,
        part: 17,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 176,
        name: 'Heavy Metal',
        aka_name: '',
        packs: [28],
        set_box: 3,
        part: 17,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 177,
        name: 'Dai Ukon',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 15,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'not-hands',
        torso: 'not-humanoid',
        accessories: 'unhumanoid',
        accessories_mythical: 'has-horns',
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 178,
        name: 'Black Satan',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 15,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 179,
        name: 'Black Buffalo',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 15,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: 'unhumanoid',
        accessories_mythical: 'has-horns',
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'A'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'A'
            }
        ]
    },
    {
        id: 180,
        name: 'Black Knight',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 15,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 181,
        name: 'Black Bear',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 15,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 182,
        name: 'Mystery Partner',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 17,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: 'clothed',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Green',
                exists: true,
                class: 'A'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 183,
        name: 'Pinchman',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 16,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'not-hands',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Green',
                exists: true,
                class: 'A'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 184,
        name: 'Geronimo (B)',
        aka_name: '',
        packs: [28],
        set_box: 2,
        part: 10,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 185,
        name: 'King The Tons (A)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 17,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-mixed',
        torso: 'not-humanoid',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'naked',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Green',
                exists: true,
                class: 'A'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 186,
        name: 'Kendaman (C)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 18,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'A'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 187,
        name: 'The Hawkman (B)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 18,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: 'has-wings',
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'A'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 188,
        name: 'Phoenixman',
        packs: [28],
        set_box: 2,
        part: 15,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'clothed',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 189,
        name: 'Mito (C)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 18,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-closed',
        torso: 'torso-chubby',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'has-cape',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'A'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 190,
        name: 'Gan Satan',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 18,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: 'has-weapon',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'A'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 191,
        name: 'Satan King',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 18,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: 'has-pants',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'A'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 192,
        name: 'Tenjokai Chojin no Kami',
        packs: [28],
        set_box: 2,
        part: 15,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: 'has-cape',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 193,
        name: 'Cobra Satan',
        aka_name: '',
        packs: [28],
        set_box: 3,
        part: 18,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'not-hands',
        torso: 'not-humanoid',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'naked',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 194,
        name: 'Haniwa Satan',
        aka_name: '',
        packs: [28],
        set_box: 3,
        part: 18,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'has-shirt',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 195,
        name: 'Sunshine (F)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 19,
        tree: 2,
        arms: 'no-arms',
        arms_y: null,
        hands: null,
        torso: 'not-humanoid',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'naked',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'B'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 196,
        name: 'Nusuto George',
        packs: [28],
        set_box: 2,
        part: 15,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: 'clothed',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 197,
        name: 'Robin Mask (D)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 19,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: 'has-cape',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'B'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 198,
        name: 'Soldierman',
        packs: [28],
        set_box: 2,
        part: 15,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'clothed',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 199,
        name: 'Ashuraman (D)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 14,
        tree: 1,
        arms: 'multi-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-mixed',
        torso: 'torso-chubby',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'B'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'A'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'A'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 200,
        name: 'Terryman (E)',
        packs: [28],
        set_box: 2,
        part: 15,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-open',
        torso: 'torso-chubby',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 201,
        name: 'King The Tons (B)',
        packs: [28],
        set_box: 2,
        part: 15,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-closed',
        torso: 'not-humanoid',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 202,
        name: 'Strongman',
        packs: [28],
        set_box: 2,
        part: 15,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'has-pants',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 203,
        name: 'Cannon Baller',
        packs: [28],
        set_box: 2,
        part: 15,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 204,
        name: 'Sunigator (E)',
        aka_name: '',
        packs: [28],
        set_box: 3,
        part: 18,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'not-hands',
        torso: 'not-humanoid',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 205,
        name: 'Powerfulman',
        packs: [28],
        set_box: 2,
        part: 15,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 206,
        name: 'Kinnikuman (I)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 19,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'B'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 207,
        name: 'Satan Prince',
        aka_name: '',
        packs: [28],
        set_box: 3,
        part: 18,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'not-hands',
        torso: 'torso-muscular',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 208,
        name: 'Dickieman',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 19,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: 'has-shirt',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 209,
        name: 'Mixer Taite (B)',
        aka_name: '',
        packs: [28],
        set_box: 3,
        part: 20,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-closed',
        torso: 'not-humanoid',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'naked',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 210,
        name: 'Kinnikuman Soldier (B)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 19,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: 'clothed',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'B'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 211,
        name: 'Buffaloman (E)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 20,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 212,
        name: 'Parthenon',
        aka_name: '',
        packs: [28],
        set_box: 3,
        part: 20,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-closed',
        torso: 'not-humanoid',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'naked',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 213,
        name: 'Kinnikuman Big Body (B)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 19,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'B'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 214,
        name: 'Robin Mask (F)',
        aka_name: '',
        packs: [28],
        set_box: 3,
        part: 20,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 215,
        name: 'Bikeman',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 20,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-mixed',
        torso: 'not-humanoid',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 216,
        name: 'The Ninja (C)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 20,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: 'has-shirt',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 217,
        name: 'Kinnikuman Mariposa (B)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 19,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: 'clothed',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 218,
        name: 'Warsman (C)',
        aka_name: '',
        packs: [28],
        set_box: 3,
        part: 20,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 219,
        name: 'Kinnikuman Zebra (B)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 19,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'clothed',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 220,
        name: 'Devil Magician',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 1,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'B'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 221,
        name: 'New Sunshine',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 21,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-mixed',
        torso: 'not-humanoid',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'naked',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'A'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'B'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 222,
        name: 'Kinnikuman (J)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 21,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'has-shirt',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 223,
        name: 'Mr. Barracuda (B)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 21,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'A'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'B'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 224,
        name: 'New Black Hole',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 21,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'A'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'B'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 225,
        name: 'Robin Mask (E)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 20,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'B'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 226,
        name: 'Ramenman (B)',
        aka_name: '',
        packs: [28],
        set_box: 3,
        part: 4,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-closed',
        hands: 'hands-open',
        torso: 'torso-muscular',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'clothed',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 227,
        name: 'Kinnikuman Great (B)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 21,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'C'
            }
        ]
    },
    {
        id: 228,
        name: 'The Ninja (D)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 21,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'clothed',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'A'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'B'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 229,
        name: 'Super Phoenix (B)',
        aka_name: 'Kinnikuman Super Phoenix',
        packs: [4, 10],
        set_box: null,
        part: 19,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-down',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'B'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: true,
                class: 'B'
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'B'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 230,
        name: 'Kendaman (A)',
        aka_name: '',
        packs: [28],
        set_box: 3,
        part: 4,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'not-hands',
        torso: 'torso-muscular',
        accessories: 'unhumanoid',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: true,
                class: 'C'
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 231,
        name: 'Warsman (D)',
        aka_name: '',
        packs: [4, 10],
        set_box: null,
        part: 21,
        tree: 1,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: null,
        accessories_wardrobe: 'clothed',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'A'
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'B'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'B'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: true,
                class: 'B'
            }
        ]
    },
    {
        id: 232,
        name: 'Turboman',
        aka_name: '',
        packs: [28],
        set_box: 1,
        part: 5,
        tree: 3,
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'A'
            },
            {
                color: 'Orange',
                exists: true,
                class: 'C'
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 233,
        name: 'Godofon Eric',
        aka_name: '',
        packs: [28],
        set_box: 4,
        part: 3,
        tree: 2,
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-open',
        torso: 'torso-chubby',
        accessories: null,
        accessories_mythical: null,
        accessories_wardrobe: 'has-cape',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Light Blue',
                exists: true,
                class: 'C'
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: true,
                class: 'C'
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: true,
                class: 'C'
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 234,
        name: 'MuscleMan',
        aka_name: 'Ring Figure',
        arms: 'has-arms',
        arms_y: 'arms-up',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-mask',
        accessories_mythical: null,
        accessories_wardrobe: 'has-armor',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 235,
        name: 'Terri-Bull',
        aka_name: 'Ring Figure',
        arms: 'has-arms',
        arms_y: 'arms-mid',
        hands: 'hands-closed',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: 'has-horns',
        accessories_wardrobe: null,
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: true,
                class: 'C'
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
    {
        id: 236,
        name: 'Satan Cross',
        aka_name: '(Non Poster Figure)',
        arms: 'multi-arms',
        arms_y: 'arms-mixed',
        hands: 'hands-mixed',
        torso: 'torso-muscular',
        accessories: 'has-helmet',
        accessories_mythical: 'has-wings',
        accessories_wardrobe: 'both-accessories',
        colors: [
            {
                color: 'Flesh',
                exists: true,
                class: 'C'
            },
            {
                color: 'Dark Blue',
                exists: false,
                class: null
            },
            {
                color: 'Light Blue',
                exists: false,
                class: null
            },
            {
                color: 'Green',
                exists: false,
                class: null
            },
            {
                color: 'Red',
                exists: false,
                class: null
            },
            {
                color: 'Salmon',
                exists: false,
                class: null
            },
            {
                color: 'Orange',
                exists: false,
                class: null
            },
            {
                color: 'Purple',
                exists: false,
                class: null
            },
            {
                color: 'Grape',
                exists: false,
                class: null
            },
            {
                color: 'Magenta',
                exists: false,
                class: null
            }
        ]
    },
];
