import React, { useEffect, useState } from 'react';
import { cleanMyDudes, handleMyDudes, localStoreKey } from '../../assets/utils';

function OptionsComponent({myDudes, setMyDudes, isFleshOnly, setFleshOnly}) {

  const [overwriteConfirm, setOverwriteConfirm] = useState(false);

  useEffect(() => {
    localStorage.setItem(localStoreKey, JSON.stringify(myDudes))
  }, [myDudes]);
  
  const saveOurDudes = () => {
    const localStorageData = JSON.stringify(cleanMyDudes(myDudes)),
          blob = new Blob([localStorageData], { type: 'application/json' }),
          link = document.createElement('a'),
          date = new Date().toJSON().slice(0, 10),
          uuid = Math.random().toString(36).slice(-4);
    link.href = URL.createObjectURL(blob);
    link.download = `muscle-collection-uid[${uuid}]--${date}.json`;
    link.click();
  }

  const renameDudeKey = (obj, oldKey, newKey) => {
    if (obj.hasOwnProperty(oldKey)) {
      obj[newKey] = obj[oldKey];
      delete obj[oldKey];
    }
    return obj;
  }

  const scrubDumbData = () => {
    // Convert bad data from past mistakes into accepted.
    // This is temporary through beta...

    let scrubbedDudes = myDudes;

    if (scrubbedDudes) {
      scrubbedDudes = renameDudeKey(scrubbedDudes, '22-C-Magenta', '22-B-Magenta');
      scrubbedDudes = renameDudeKey(scrubbedDudes, '22-B-Orange', '22-C-Orange');
      scrubbedDudes = renameDudeKey(scrubbedDudes, '33-C-Orange', '22-B-Orange');
      scrubbedDudes = renameDudeKey(scrubbedDudes, '33-C-Magenta', '33-B-Magenta');
      scrubbedDudes = renameDudeKey(scrubbedDudes, '95-A-Green', '95-C-Green');

      if (scrubbedDudes.hasOwnProperty('59-B-Orange')) {
        delete scrubbedDudes['59-B-Orange'];
      } 
      if (scrubbedDudes.hasOwnProperty('226-C-Orange')) {
        delete scrubbedDudes['226-C-Orange'];
      } 
    }

    // Double scrub to remove any possible duplicates.
    let secondScrubDudes = scrubbedDudes;
    for (const key in scrubbedDudes) {
      secondScrubDudes[key] = scrubbedDudes[key]
    }

    setMyDudes(secondScrubDudes);
    alert('All done correcting collection data. Your inventory should display correctly now. The app will refresh now, thank you for helping test! -CW')
    window.location.reload();
  }
  
  const importDudes = (e) => {
    const file = e.target.files[0],
          reader = new FileReader();
    
    setMyDudes({});
    reader.onload = (e) => {
      let dudesBackup = {}
      try {
        dudesBackup = JSON.parse(e.target.result);
        alert('If valid format than your collection has been imported.');
      } catch {
        alert('Invalid backup file, unable to parse collection.');
        return;
      }

      Object.keys(dudesBackup).forEach((dude) => {
        handleMyDudes(dude, setMyDudes);
      });
    };
  reader.readAsText(file);
}

const handleFleshDudes = (e) => {
  setFleshOnly(e.target.value);
  alert(`As a ${isFleshOnly === 'false' ? 'Fleshy' : 'Color'} collector you see ${isFleshOnly === 'false' ? 'LESS' : 'MORE'} additional features for convenience.`);
}

  return (
    <>
      <div className="dFlex flexColumn">
        <section id="flesh-toggler">
          <h1>Collection Preference</h1>
          <label>
            <input
              type="radio"
              value={"false"}
              name="fleshOnly"
              checked={isFleshOnly === 'false'}
              onChange={handleFleshDudes}
            />
            &nbsp;I collect <strong>ALL</strong> The Colors
          </label><br/>
          <label>
            <input
              type="radio"
              value="true"
              name="fleshOnly"
              checked={isFleshOnly === 'true'}
              onChange={handleFleshDudes}
            />
            &nbsp;I collect the Fleshy / Pink <strong>ONLY</strong>
          </label>
        </section>
        <section id="backup-view">
          <h1>Backup your collection!</h1>
          <p>
              Your collection data is stored <strong>locally only</strong>. So make sure to back-up after changes to avoid data loss!
          </p>
          <ol>
            <li><strong>If you clear your browsers "Local Storage" you will lose your <i>local</i> collection data.</strong></li>
            <li>You can restore your collection, from your back-up at any time, on any other device but are responsible for saving/sharing yourself.</li>
          </ol>
          <p>
            This avoids me having to host your data but allows you to use the app offline and share your collection data easily. -Chris
          </p>
          <hr/>
          <br/>
          <h2>EXPORT a backup file of your current collection data</h2>
          <button onClick={saveOurDudes}>Click to create Collection Backup (JSON) file</button><br/><br/>
          <h2>IMPORT a backup file to restore your collection data</h2>
          <label className="dFlex flexCenter">
            <input type="checkbox"
              id="overwrite-confirm"
              checked={overwriteConfirm}
              onChange={(e) => setOverwriteConfirm(e.target.checked)}/>
            
            <span style={{color: 'red'}}>I UNDERSTAND</span><strong>&nbsp;this overwrites your current loaded collection data&nbsp;</strong>.
          </label><br/><br/>
          <input type="file"
            onChange={importDudes}
            disabled={!overwriteConfirm}
            />
        </section>
        <br/><br/><br/>
        <hr class="w100"/>
        <br/>
        <section>
          <h1 style={{color: 'red'}}>Beta Temporary Options</h1>
          <p>As I iron out any missing figures or mismatched figures etc during this beta testing phase you may need to
             occasionally hit the button below to correct your local collection data.</p>
          <p>After which I advise you to create/export a fresh new collection JSON file with the option above.</p>
          <br/>
          <button onClick={scrubDumbData}>CLEAN MY COLLECTION DATA FROM PREVIOUS BUGS</button>
        </section>
      </div>
    </>
  );
}

export default OptionsComponent;